//libraries
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, Dropdown, NavDropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch, useParams } from 'react-router-dom';

import './NewLandingFramework.css'

//components
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';

//assets
import { faStar, faSearch } from '@fortawesome/free-solid-svg-icons';
import logo_framework from '../../assets/img/avap_logo.png'
import logoMarketplace from '../../assets/img/logos/logo-marketplace-negativo.svg'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'

import icon_dev from '../../assets/img/logos/icono_DSs.svg'
import icon_api from '../../assets/img/logos/icon_APIControVersion.svg'
import icon_publisher from '../../assets/img/logos/icono_publisher.svg'
import icon_linter from '../../assets/img/logos/icon_avap.svg'

const icons = {
    "icon_dev": icon_dev,
    "icon_api": icon_api,
    "icon_publisher": icon_publisher,
    "icon_linter": icon_linter,

  };

import icon_verified from '../../assets/img/ui_icons/icono-verificado@2x.png'
import screenshot from '../../assets/img/screenshot.png'



import { BrowserView, MobileView } from 'react-device-detect';

import appsData from './appsData.json'
import TopMenuBar from './TopMenuBar';



type DashProps = {
    sider: any
};



const MarketplaceStoreDetails = () => {

    const { app } = useParams(); 
    const appData = appsData.apps.find(application => application.id === app);
    const appIcon = icons[appData.icon];

    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // favicon
        return () => {
            document.head.removeChild(link);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <div>
            <BrowserView>
            <TopMenuBar/>
                <div id='marketplaceStoreDetail' className="w-100 pt-50">
                    <div id="topBar">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="linksWrapper">
                                        <a href="/marketplaceStore">
                                            <Image src={logoMarketplace} />
                                        </a>
                                        <span>|</span>
                                        <a href="/marketplaceStore/company" className='c-white'>Company</a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div id='topInfo' className='w-100 bg-division'>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="topInfoWrapper">
                                        <div className="icon">
                                            <Image src={appIcon} />
                                        </div>
                                        <div className="info">
                                            <div className="title">{appData.name}</div>
                                            <div className="details">
                                                <div className='txt-22 c-black  txt-bold d-inline-flex'>
                                                    AVAP Dev Ext.
                                                    <Image className='badge' src={icon_verified} />
                                                    <a className='c-blue' href={appData.url}> productweb.com</a></div>

                                                <div>| {appData.installations} installs |</div>
                                                <div>
                                                    <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                    <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                    <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                    <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                    <FontAwesomeIcon icon={faStar} className="c-gray" size="1x" />
                                                </div>
                                                <div>| {appData.type}</div>
                                            </div>
                                            <div className="desc txt-20 txt-bold">
                                                <p>
                                                    {appData.description}
                                                </p>
                                            </div>
                                            <div className='buttonsWrapper'>
                                                <a className='installButton' href={appData.install_url}>Install</a>
                                                <a className='link' href="">Trouble installing?</a>
                                            </div>

                                        </div>

                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <Container id='description'>
                        <Row>
                            <Col>
                                <h1 className='title'>
                                    AVAP Dev Extension
                                </h1>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p className='content'>
                                    <a href=""> AVAP Dev Extension</a> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
                                    <a href="">Sign up for a AVAP Dev Extension free trial.</a>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Image src={screenshot} className='pb-screenshot'/>
                            </Col>
                        </Row>
                    </Container>


                </div>
                <FooterMenuAccess green={true} />

            </BrowserView>


        </div>
    )
}

export default MarketplaceStoreDetails;