import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown, Carousel, } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'
import logo_framework_1 from '../../assets/img/AVAP Cloud_Professional_Logo.png'
import logo_framework_2 from '../../assets/img/AVAP Cloud_Business_Logo.png'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'

import cloud_1 from '../../assets/img/icons/Cloud_01.svg'
import cloud_2 from '../../assets/img/icons/Cloud_02.svg'
import cloud_3 from '../../assets/img/icons/Cloud_03.svg'
import cloud_4 from '../../assets/img/icons/Cloud_04.svg'
import cloud_5 from '../../assets/img/icons/Cloud_05.svg'
import cloud_6 from '../../assets/img/icons/Cloud_06.svg'

import logo_framework_titulo_cloud from '../../assets/img/AVAP_Cloud_P.png'



import cabecera from '../../assets/img/foto_Cloud.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'

import vineta_CN from '../../assets/img/vineta_cloud.png'
import gafico_cn_i from '../../assets/img/grafico_cloud_i.png'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';
import TopMenuBar from './TopMenuBar';



type DashProps = {
    sider: any
};



const NewLandingCollaborative = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // favicon
        return () => {
            document.head.removeChild(link);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <>
            <BrowserView>

                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}>

                <TopMenuBar/>



                    <div onClick={() => { cleanMenuAll() }}>

                        <div onClick={() => { cleanMenuAll() }} className="d-flex flex-column align-items-center">


                            <div className='d-flex flex-column  min-vh-100 align-items-center'>

                                <Image src={cabecera} style={{ marginTop: '40px' }} width="" alt="Logo" />
                                <Image src={logo_framework_titulo_cloud} style={{
                                    marginTop: '80px',
                                    position: 'relative',
                                    width: '300px'
                                }} width="" alt="Logo" />

                                <div
                                    className='landingFont0'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px'
                                    }}
                                >Transform the development, management,</div>


                                <div
                                    className='landingFont0'
                                    style={{
                                        marginTop: '0px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px'
                                    }}
                                >and security of APIs with AVAP Cloud, a<span className='textBold'></span><span className='textBold'></span></div>
                                <div className='landingFont0 textBold' style={{ color: 'black' }}>native management service</div>
                                <div
                                    className='landingFont3'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        marginLeft: '25%',
                                        marginRight: '25%',
                                        textAlign: 'center'
                                    }}
                                >Cloud platform designed for users to leverage the full potential of AVAP

                                    Framework products and services. Gain flexible and scalable access to

                                    advanced technological solutions without the need for local infrastructure.

                                    AVAP Cloud is a universal and powerful service that enhances your AVAP

                                    experience while also being compatible with other programming

                                    environments and language servers.</div>

                            </div>
                        </div>

                        <div style={{
                            backgroundColor: '#ffffff',
                            height: '200px',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}>
                            {/*}
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>

            */}
                            <button
                                onClick={() => { history.push('/avap_cloud') }}
                                className='custom-button-blue textBold'
                                style={{ marginTop: '20px' }}
                            >
                                Start with AVAP Cloud
                            </button>

                        </div>
                        <div style={{
                            backgroundColor: '#fafafa',
                            height: '100%',
                            textAlign: 'center',
                            paddingTop: '30px',
                            paddingBottom: '20px'
                        }}>
                            {/*}
                <div 
                    className='landingFont1'
                >
                    The total API solution
                </div>
                <div 
                    className='landingFont1'
                >
                   
                </div>
                <div 
                    className='landingFont3'
                    style={{ 
                        marginTop:'20px',
                        width:'33%', 
                        marginLeft:'auto', 
                        marginRight:'auto' 
                    }}
                >
                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.
                </div>

*/}
                            <div
                                id='bloque de columnas'
                                style={{
                                    width: '50%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    paddingTop: '80px',
                                    paddingBottom: '80px',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={cloud_1} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>SaaS</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>Achieve full deployment using logical servers. Our regional Kubernetes network enables you to deploy AVAP language, other language servers, AVS, and AVAP Gateway Advanced servers as required.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={cloud_2} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>API development and operation</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>Cloud platform for developing, maintaining, and publishing APIs.
                                            Offering advanced tools for API development, testing, deployment, and management to ensure a streamlined and secure workflow.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={cloud_3} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>Logical infrastructure design</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>Access a visual tool that allows you to configure deployment and
                                            monitor its operation.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={cloud_4} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>Universal Platfrom</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>In addition to the seamlessly connection to AVAP language servers, AVS is compatible with Java, Python and any other API servers.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={cloud_5} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>Real-time activation</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>AVAP Cloud Professional and Business offer real-time deployments, utilizing the logical infrastructure provisioning model.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={cloud_6} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>Database control and flexibility</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>The data used by APIs can be stored in AVAP databases or in proprietary servers, enabling the use of diverse database types.</div>
                                    </div>
                                </div>

                                {/*}
                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={lenguaje} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Lenguaje Alto Nivel</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Foco en las necesidades de creación de APIs, implementando procesos básicos como comandos del lenguaje y gestionando las estructuras de datos de entrada y salida como estructuras de datos nativas.</div>
                        </div>

                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={virtualidad} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Virtualidad</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>El atributo de virtualidad del lenguaje AVAP se da ya que sus definiciones dinámicas se alojan en la nube. Por lo que, para el desarrollo, el servidor de lenguaje únicamente lee los comandos, recupera estas definiciones y compone un Virtual Code que ejecuta la máquina virtual (parseador)</div>
                        </div>


                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={inteligencia} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Inteligencia</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Al es parte de nuestro ADN, integrándose en todas las funciones y capacidades de AVAP, desde la asistencia, desarrollo y hasta el enrutamiento de servidores.</div>
                        </div>
*/}



                            </div>


                        </div>


                        <div style={{
                            backgroundColor: '#ffffff', height: '100%',
                            paddingTop: '80px',
                            paddingBottom: '80px',
                        }}>
                            <div className='landingFont0'
                                style={{
                                    marginTop: '0px',

                                    position: 'relative',
                                    color: 'black',
                                    lineHeight: '50px',
                                    textAlign: 'center'
                                }}
                            >Top AVAP Cloud <span className='textBold'>Benefits</span></div>
                            <div className='landingFont0'
                                style={{
                                    marginTop: '0px',

                                    position: 'relative',
                                    color: 'black',
                                    lineHeight: '50px',
                                    textAlign: 'center'
                                }}
                            ></div>

                            <div style={{ paddingTop: '40px', width: '100%', display: 'flex' }}>

                                <div style={{ width: '50%' }}>
                                    <div className='landingFont3b' style={{ marginTop: '20px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Empowers strategic project focus through delegated infrastructure management.</div>

                                    <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Simplifies the management of complex infrastructures with a centralized designer.</div>

                                    <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Accelerates API development and launch, reducing operational costs and speeding up time to market.</div>

                                    <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Total control of investment in each project.</div>

                                    <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Effective management of development teams.</div>

                                    <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Optimization of infrastructure investment for API deployment.</div>

                                    {/*<div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Ensure security and control over data and API access management.</div>

    <div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Access detailed documentation and educational resources to facilitate integration and use of AVAP Framework.</div>*/}
                                </div>
                                <div style={{ width: '50%' }}>
                                    <div style={{ marginTop: '0px', paddingLeft: '10px' }} className='landingFont2 textBold'>API's Lifecycle</div>
                                    <Image src={gafico_cn_i} style={{ width: '80%', marginLeft: '10px', marginTop: '20px', marginRight: 'auto' }} width="" />

                                </div>

                            </div>

                        </div>

                        <div style={{ backgroundColor: '#fafafa', paddingBottom: '80px' }}>
                            <div
                                className='landingFont0'
                                style={{
                                    marginTop: '0px',
                                    paddingTop: '80px',
                                    position: 'relative',
                                    color: 'black',
                                    lineHeight: '50px',
                                    textAlign: 'center'
                                }}
                            >AVAP Cloud <span className='textBold'>Memberships</span></div>

                            <div style={{ marginTop: '40px', width: '50%', marginLeft: 'auto', marginRight: 'auto', display: 'flex' }}>

                                <div style={{ width: '33%' }}>
                                    <div style={{
                                        borderRadius: '10px',
                                        width: 'auto',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        borderColor: '#dcdcdc',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }}>

                                        <Image src={logo_framework_1} style={{
                                            marginTop: '20px',
                                            width: '70%'
                                        }} width="" alt="Logo" />
                                        <div style={{ width: '100%', backgroundColor: '#eaeaea', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                                            <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Professional</span>
                                        </div>
                                        <div style={{ minHeight: '120px', height: '130px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Membership that allows you to explore and start taking advantage AVAP technology</div>
                                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                            <button onClick={() => { history.push('/avap_cloud/professional') }} style={{ paddingLeft: '10px', paddingRight: '10px', width: '90%', fontSize: '0.9vw' }} className='custom-button-blue textBold'>GET AVAP Professional</button>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ width: '33%' }}>
                                    <div style={{
                                        borderRadius: '10px',
                                        width: 'auto',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        borderColor: '#dcdcdc',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }}>

                                        <Image src={logo_framework_2} style={{
                                            marginTop: '20px',
                                            width: '70%'
                                        }} width="" alt="Logo" />
                                        <div style={{ width: '100%', backgroundColor: '#eaeaea', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                                            <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Business</span>
                                        </div>
                                        <div style={{ minHeight: '120px', height: '130px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Pre-configured AVAP infrastructure deployment, enabling you concentrate on developing and publishing APIs.</div>
                                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                            <button onClick={() => { history.push('/avap_cloud/business') }} style={{ paddingLeft: '10px', paddingRight: '10px', width: '90%', fontSize: '0.9vw' }} className='custom-button-blue textBold'>GET AVAP Cloud Bussines</button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '33%' }}>
                                    <div style={{
                                        borderRadius: '10px',
                                        width: 'auto',
                                        marginLeft: '10px',
                                        marginRight: '10px',
                                        borderColor: '#dcdcdc',
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }}>

                                        <Image src={logo_framework_titulo_cloud} style={{
                                            marginTop: '20px',
                                            width: '70%'
                                        }} width="" alt="Logo" />
                                        <div style={{ width: '100%', backgroundColor: '#eaeaea', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                                            <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Enterprise</span>
                                        </div>
                                        <div style={{ minHeight: '120px', height: '130px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Maintain full control over the deployment of AVAP infrastructure, tailored for corporations.</div>
                                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                            <button onClick={() => { window.open('mailto:support@101obex.com', '_self') }} style={{ paddingLeft: '10px', paddingRight: '10px', width: '90%', fontSize: '0.9vw' }} className='custom-button-blue textBold'>Contact Us</button>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                        <div>
                            <FooterMenuAccess green={true} />
                        </div>

                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <MobileNavbar />

                {/* <Disclaimer /> */}

                <div id='mobileCloud' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo_cloud} className='logos' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    Transform the development, management, and security of APIs with AVAP Cloud, a <br /><span className=' textBold'>native management service</span>
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                    Cloud platform designed for users to leverage the full potential of AVAP
                                    Framework products and services. Gain flexible and scalable access to advanced
                                    technological solutions without the need for local infrastructure.
                                    AVAP Cloud is a universal and powerful service that enhances your AVAP experience
                                    while also being compatible with other programming environments and language servers.

                                </p>

                                {/*}  <button onClick={() => { history.push('/Register') }} className='bluebutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image className='mt-5' src={cloud_1} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>SaaS</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Achieve full deployment using logical servers. Our regional Kubernetes network enables you to deploy AVAP language, other language servers,
                                        AVS, and AVAP Gateway Advanced servers as required.
                                    </div>

                                </div>
                            </div>
                            <div className=" mb-5">
                                <div className="col-md-2">
                                    <Image src={cloud_2} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title '>API development and operation</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Cloud platform for developing, maintaining, and publishing APIs. Offering advanced tools for API development, testing, deployment, and management to ensure a streamlined and secure workflow.</div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cloud_3} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Logical infrastructure design</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Access a visual tool that allows you to configure deployment and monitor its operation.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cloud_4} style={{ width: '50%' }} width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Universal Platfrom
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        In addition to the seamlessly connection to AVAP language servers, AVS is compatible with Java, Python and any other API servers.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={cloud_5} style={{ width: '50%' }} width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>

                                        Real-time activation
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        AVAP Cloud Professional and Business offer real-time deployments, utilizing the logical infrastructure provisioning model.
                                    </div>

                                </div>
                            </div>
                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={cloud_6} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Database control and flexibility</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        The data used by APIs can be stored in AVAP databases or in proprietary servers, enabling the use of diverse database types.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP Benefits
                                </h1>
                            </div>
                            <div className="col-md-12">
                                <p className='title'>API's Lifecycle</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Image src={gafico_cn_i} style={{ width: '100%', padding: "10px" }} width="" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Empowers strategic project focus through delegated infrastructure management.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Simplifies the management of complex infrastructures with a centralized designer.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Accelerates API development and launch, reducing operational costs and speeding up time to market.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Total control of investment in each project.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Effective management of development teams.</div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Optimization of infrastructure investment for API deployment.</div>

                            </div>
                        </div>
                    </section>

                    <section id='memberships' className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className='hero-title text-center'>AVAP Cloud <span className=' textBold'>Memberships</span></h1>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Carousel>
                                        <Carousel.Item>
                                            <div>
                                                <div style={{
                                                    borderRadius: '10px',
                                                    width: 'auto',
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    borderColor: '#dcdcdc',
                                                    borderWidth: '1px',
                                                    borderStyle: 'solid',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    backgroundColor: 'white'
                                                }}>

                                                    <Image src={logo_framework_1} style={{
                                                        marginTop: '20px',
                                                        width: '70%'
                                                    }} width="" alt="Logo" />
                                                    <div style={{ width: '100%', backgroundColor: '#eaeaea', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                        <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Professional</span>
                                                    </div>
                                                    <div style={{ minHeight: '120px', height: '130px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Membership that allows you to explore and start taking advantage AVAP technology</div>
                                                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                        <button onClick={() => { history.push('/avap_cloud/professional') }} className='bluebutton'>GET AVAP Professional</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div>
                                                <div style={{
                                                    borderRadius: '10px',
                                                    width: 'auto',
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    borderColor: '#dcdcdc',
                                                    borderWidth: '1px',
                                                    borderStyle: 'solid',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    backgroundColor: 'white'
                                                }}>

                                                    <Image src={logo_framework_2} style={{
                                                        marginTop: '20px',
                                                        width: '70%'
                                                    }} width="" alt="Logo" />
                                                    <div style={{ width: '100%', backgroundColor: '#eaeaea', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                        <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Business</span>
                                                    </div>
                                                    <div style={{ minHeight: '120px', height: '130px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Pre-configured AVAP infrastructure deployment, enabling you concentrate on developing and publishing APIs.</div>
                                                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                        <button onClick={() => { history.push('/avap_cloud/business') }} className='bluebutton'>GET AVAP Cloud Bussines</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </Carousel.Item>

                                        <Carousel.Item>
                                            <div >
                                                <div style={{
                                                    borderRadius: '10px',
                                                    width: 'auto',
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    borderColor: '#dcdcdc',
                                                    borderWidth: '1px',
                                                    borderStyle: 'solid',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    backgroundColor: 'white'
                                                }}>

                                                    <Image src={logo_framework_titulo_cloud} style={{
                                                        marginTop: '20px',
                                                        width: '70%'
                                                    }} width="" alt="Logo" />
                                                    <div style={{ width: '100%', backgroundColor: '#eaeaea', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                        <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Enterprise</span>
                                                    </div>
                                                    <div style={{ minHeight: '120px', height: '130px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Maintain full control over the deployment of AVAP infrastructure, tailored for corporations.</div>
                                                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                        <button onClick={() => { window.open('mailto:support@101obex.com', '_self') }} className='bluebutton'>Contact Us</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </div>

                    </section>


                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </>
    )
}

export default NewLandingCollaborative;