import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import FooterMenuMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'
import avap_logo from '../../assets/img/avap_logo.png'
import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'

import iconos_familia from '../../assets/img/iconos_familia.png';
import logo_framework_titulo from '../../assets/img/twelveHundred_inversionistas@2x.png'
import cabecera from '../../assets/img/textura_cabecera@2x.png'
import cabecera2 from '../../assets/img/logotipo101@2x.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import TopMenuBar from './TopMenuBar';

import collaborative2 from '../../assets/img/collaborative2.png'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';

type DashProps = {
    sider: any
};



const NewLandingABOUT = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // favicon
        return () => {
          document.head.removeChild(link);
        };
      }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-08SZKPG9V0');
            ReactGA.send(
                { hitType: 'pageview', 
                    page: window.location.pathname, 
                    title: '101OBEX, CORP' 
                }
            );
            //ReactGA.send('pageview')
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <>


            <BrowserView>
                <div className='d-flex flex-column' style={{ backgroundColor: '#fafafa' }}>

                    
                <TopMenuBar/>


                    <div onClick={() => { cleanMenuAll() }}>

                        <div onClick={() => { cleanMenuAll() }} className="d-flex flex-column align-items-center">


                            <div className='d-flex flex-column  min-vh-100 align-items-center'>
                                <div >

                                    <div style={{ width: '80%', textAlign: 'center' }}>
                                        <Image style={{ position: 'absolute', paddingTop: '200px' }} src={cabecera2} width="300px" alt="Logo" />
                                    </div >
                                    <div className='landingFont1' style={{ position: 'absolute', paddingTop: '300px', width: '100%', textAlign: 'center' }}>
                                        An <span className='textBold'>API Virtualization</span> Company
                                    </div>

                                    <Image src={cabecera} style={{ marginTop: '40px' }} width="" alt="Logo" />
                                </div>







                                <div style={{ width: '100%', backgroundColor: 'white', paddingBottom: '40px' }}>
                                    <div style={{ marginLeft: '10%', marginRight: '10%', width: '80%', display: 'flex' }}>
                                        <div style={{ width: '50%' }}>
                                            <div style={{ textAlign: 'start', backgroundColor: 'white', paddingLeft: '80px' }}>
                                                <div
                                                    className='landingFont3b textBold'
                                                >
                                                    Corporate Spirit ...
                                                </div>
                                                <div
                                                    className='landingFont1'
                                                >

                                                </div>
                                                <div
                                                    className=''
                                                    style={{
                                                        marginTop: '20px',
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        textAlign: 'start'
                                                    }}
                                                >
                                                    101OBEX, CORP stands out for its orderly, serious, and rigorous character, fundamental aspects that reflect our commitment to quality and excellence. As the owner, manufacturer, and marketer of AVAP technology, we maintain a solid and well-structured corporate approach, ensuring that every aspect of our operation meets the highest industry standards. Our dedication to precision and reliability is the cornerstone upon which we build long-lasting relationships with our clients and partners, guaranteeing robust and cutting-edge solutions in the realm of API lifecycle management.
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ width: '50%' }}>

                                            <div style={{ textAlign: 'start', backgroundColor: 'white', paddingLeft: '80px' }}>
                                                <div
                                                    className='landingFont3b textBold'
                                                >
                                                    Startup Heart!
                                                </div>
                                                <div
                                                    className='landingFont1'
                                                >

                                                </div>
                                                <div
                                                    className=''
                                                    style={{
                                                        marginTop: '20px',
                                                        width: '100%',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                        textAlign: 'start'
                                                    }}
                                                >
                                                    Alongside our strong corporate foundation, 101OBEX, CORP takes pride in its young and innovative spirit. We are a company that does not wait for changes to happen but actively drives them, with an entrepreneurial and bold mindset that propels us to explore new frontiers. With a daring vision and a proactive approach, our team is continually seeking opportunities to revolutionize the market and create innovative solutions that address the emerging needs of our clients, reaffirming our role as leaders in the technological evolution of the sector.
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div style={{ justifyContent: 'center', textAlign: 'center', width: '100%' }}>
                                    <div className='landingFont1' style={{ position: 'absolute', width: '100%', paddingTop: '80px' }}>Our Investors</div>
                                    <Image src={logo_framework_titulo} style={{
                                        marginTop: '180px',
                                        marginBottom: '40px',
                                        position: 'relative',
                                        width: '50%'
                                    }} width="" alt="Logo" />
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: 'white', width: '100%', justifyContent: 'center', textAlign: 'center', paddingTop: '60px', paddingBottom: '60px' }}>
                            <div style={{ width: '100%' }}>
                                <Image src={avap_logo} style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    position: 'relative',
                                    width: '200px'
                                }} width="" alt="Logo" />
                            </div>
                            <div style={{ width: '100%' }}>
                                <Image src={iconos_familia} style={{
                                    marginTop: '00px',
                                    position: 'relative',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '200px'
                                }} width="" alt="Logo" />
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', backgroundColor: 'white' }}>
                            <div
                                className='landingFont1'
                            >
                                An <span className='textBold'>API Virtualization</span> Platform
                            </div>
                            <div
                                className='landingFont1'
                            >

                            </div>
                            <div
                                className='landingFont3'
                                style={{
                                    marginTop: '20px',
                                    width: '33%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                Empower your API lifecycle with AVAP: Unleashing the full potential of virtual API management for unparalleled efficiency and innovation.
                            </div>
                        </div>











                        <div style={{ backgroundColor: 'white', height: '80px' }}>

                        </div>




                        <div>
                            <FooterMenuAccess green={true} />
                        </div>

                    </div>
                </div>
            </BrowserView>
       <MobileView>
                <Navbar id='frameworkNav' collapseOnSelect expand="lg" bg="light" variant="light" className='fixed-top' style={{ width: '100%' }}>
                    <Container>
                        <Navbar.Brand href="/framework">
                            <Image src={logo_framework} style={{marginTop:'-10px', paddingLeft:'15px'}} alt="Logo" className="mb-1" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='mr-2' >
                            <div className='hamburguer' style={{marginTop:'-10px'}}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav"  style={{backgroundColor:'white', marginTop:'-20px'}}  className="w-100 justify-content-between align-items-center">
                            <div className="d-flex flex-wrap">
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu1(!menu1); }} className='hideLink'>
                                    Products
                                    <div hidden={!menu1} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Products" id="DropdownProducts" className='showLink'>

                                    <div className="section-header">Build Virtual APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP (Advanced Virtual API Programming)
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                        AVAP API Extensions for Microsoft Visual Code
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                        AVAP Dev Studio
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Test & Deliver APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                        AVAP API Version Control
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                        AVAP API Publisher
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Run APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                        AVAP Virtual Server
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP Interpreter & Language Server
                                    </div>

                                </NavDropdown>



                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu2(!menu2); }} className='hideLink'>
                                    Services
                                    <div hidden={!menu2} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Services" id="DropdownProducts" className='showLink'>
                                    <div className="section-header">Professional</div>
                                    <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                        AVAP Collaborative Network
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Professional
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">Business</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Business
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Corporate</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Enterprise
                                    </div>

                                </NavDropdown>






                                <Nav.Link onClick={() => { history.push('/avapid'); cleanMenuAll(); }}>
                                    AVAP ID
                                    <div hidden={!menu3} className="menu-highlight"></div>
                                </Nav.Link>

                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu4(!menu4); }} className='hideLink'>
                                    Resources
                                    <div hidden={!menu4} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Resources" id="DropdownProducts" className='showLink'>
                                    <div className="section-header">Developer Support</div>
                                    {/*<div className="section-content" onClick={() => { history.push('/default'); }}>
                                        AVAP Developer Portal
                                    </div>*/}
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                        AVAP Communities
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">Knowledge Center</div>
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                        AVAP Alexandria
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                        AVAP Blog
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">API Products</div>
                                   {/* <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                        AVAP Marketplace
                                    </div>*/}
                                </NavDropdown>


                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu5(!menu5); }} className='hideLink'>
                                    Company
                                    <div hidden={!menu5} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Company" id="DropdownProducts" className='showLink'>
                                    <div className="section-content" onClick={() => { history.push('/about'); }}>
                                        About Us
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                        Contact Us
                                    </div>
                                </NavDropdown>
                            </div>
                            {/*<button
                                className="greenbutton"
                                onClick={() => { history.push('/login'); }}
                            >
                                Sign in with your AVAP ID
                            </button>*/}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>


                {menu1 && (
                    <>

                        <div className="menu-dropdown ">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Build Virtual APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP (Advanced Virtual API Programming)
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                        AVAP API Extensions for Microsoft Visual Code
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                        AVAP Dev Studio
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Test & Deliver APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                        AVAP API Version Control
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                        AVAP API Publisher
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Run APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                        AVAP Virtual Server
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP Interpreter & Language Server
                                    </div>
                                </div>
                            </div>
                        </div>


                    </>
                )}

                {menu2 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Professional</div>
                                    <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                        AVAP Collaborative Network
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Professional
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Business</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Business
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Corporate</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Enterprise
                                    </div>
                                </div>
                            </div>
                        </div>


                    </>
                )}

                {menu3 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Partner Ecosystem</div>
                                    <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                        Find a Partner
                                    </div>
                                    <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                        Become a Partner
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {menu4 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Developer Support</div>
                                    {/*<div className="section-content" onClick={() => { history.push('/default'); }}>
                                        AVAP Developer Portal
                                    </div>*/}
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                        AVAP Communities
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Knowledge Center</div>
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                        AVAP Alexandria
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                        AVAP Blog
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">API Products</div>
                                   {/* <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                        AVAP Marketplace
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {menu5 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Company</div>
                                    <div className="section-content" onClick={() => { history.push('/about'); }}>
                                        About Us
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                        Contact Us
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}


                {/* <Disclaimer /> */}

                <div id='mobileGateway' className="wrapper" >


                    <header className='w-100 headbanner mt-5' style={{ textAlign:'center', height:'100vh', backgroundImage: `url(${cabecera})`, }} >
                    


                   {/*} <div style={{ width: '80%', textAlign: 'center' }}>*/}
                            <Image style={{ paddingTop: '20vh' }} src={cabecera2} width="300px" alt="Logo" />
                   {/*} </div >*/}




                    <div className='landingFont1' style={{ position: 'absolute', paddingTop: '1em', width: '100%', textAlign: 'center' }}>
                                        An <span className='textBold'>API Virtualization</span> Company
                    </div>
                    
                    </header>


                    <section style={{marginTop:'4em'}} id='intro' className='container'>
 
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                Corporate Spirit...
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                101OBEX, CORP stands out for its orderly, serious, and rigorous character, fundamental aspects that reflect our commitment to quality and excellence. As the owner, manufacturer, and marketer of AVAP technology, we maintain a solid and well-structured corporate approach, ensuring that every aspect of our operation meets the highest industry standards. Our dedication to precision and reliability is the cornerstone upon which we build long-lasting relationships with our clients and partners, guaranteeing robust and cutting-edge solutions in the realm of API lifecycle management.
                                </p>

                                
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                Startup Heart
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                Alongside our strong corporate foundation, 101OBEX, CORP takes pride in its young and innovative spirit. We are a company that does not wait for changes to happen but actively drives them, with an entrepreneurial and bold mindset that propels us to explore new frontiers. With a daring vision and a proactive approach, our team is continually seeking opportunities to revolutionize the market and create innovative solutions that address the emerging needs of our clients, reaffirming our role as leaders in the technological evolution of the sector.
                                </p>

                                
                            </div>
                        </div>



                    </section>
                    <div style={{backgroundColor:'#fafafa'}}>

                    <section style={{width:'90vw', marginLeft:'auto', marginRight:'auto', backgroundColor:'#fafafa'}}>

                    <div className="row" style={{paddingTop:'2em'}}>
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                Our Investors
                                </h1>
                            </div>
                        </div>


                    <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='logos' alt="Logo" />
                            </div>
                        </div>


                    </section>
</div>


                    <section style={{textAlign:'center', marginTop:'4em', marginBottom:'4em'}}>
<div>

                    <div style={{ width: '100%' }}>
                                <Image src={avap_logo} style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    position: 'relative',
                                    width: '60%'
                                }} width="" alt="Logo" />
                            </div>


                    <div style={{ width: '100%' }}>
                                <Image src={iconos_familia} style={{
                                    marginTop: '00px',
                                    position: 'relative',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '60%'
                                }} width="" alt="Logo" />
                            </div>

                            </div>




                            <div style={{ textAlign: 'center', backgroundColor: 'white', marginTop:'2em' }}>
                            <div style={{width:'60%', marginLeft:'auto', marginRight:'auto'}}
                                className='landingFont2'
                            >
                                An <span className='textBold'>API Virtualization</span> Platform
                            </div>
                            <div
                                className='landingFont2'
                            >

                            </div>
                            <div
                                className='landingFont3'
                                style={{
                                    marginTop: '20px',
                                    width: '60%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                Empower your API lifecycle with AVAP: Unleashing the full potential of virtual API management for unparalleled efficiency and innovation.
                            </div>
                        </div>



                    </section>




                </div>


                <div>
                    <FooterMenuMobile green={true} />
                </div>




            </MobileView>
        </>
    )
}

export default NewLandingABOUT;