import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'


import icn_1 from '../../assets/img/icons/VersionControl_01.svg'
import icn_2 from '../../assets/img/icons/VersionControl_02.svg'
import icn_3 from '../../assets/img/icons/VersionControl_03.svg'


import logo_framework_titulo from '../../assets/img/AVAP_APIVersionContro.png'
import cabecera from '../../assets/img/foto-VersionControl.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'

import vineta_CN from '../../assets/img/vineta_avap.png'
import gafico_cn_i from '../../assets/img/grafico_avap_i.png'

import { BrowserView, MobileView } from 'react-device-detect';

import favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import TopMenuBar from './TopMenuBar';

type DashProps = {
    sider: any
};



const NewLandingVersionControl = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }
    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // favicon
        return () => {
            document.head.removeChild(link);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <div>
            <BrowserView>
                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}></div>

                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}>

                <TopMenuBar/>

                    <div onClick={() => { cleanMenuAll() }}>

                        <div onClick={() => { cleanMenuAll() }} className="d-flex flex-column align-items-center">


                            <div className='d-flex flex-column  min-vh-100 align-items-center'>

                                <Image src={cabecera} style={{ marginTop: '40px' }} width="" alt="Logo" />
                                <Image src={logo_framework_titulo} style={{
                                    marginTop: '80px',
                                    position: 'relative',
                                    width: '500px'
                                }} width="" alt="Logo" />

                                <div
                                    className='landingFont0'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px'
                                    }}
                                >AVAP API Version Control</div>


                                {/*}
                <div
                    className='landingFont0'
                    style={{
                        marginTop:'0px', 
                        position:'relative', 
                        color:'black',
                        lineHeight:'50px'
                    }}                
                >free <span className='textBold'>peer-to-peer</span> network for API <span className='textBold'></span></div>
<div className='landingFont0' style={{ color:'black' }}>development and deployment</div>*/}

                                <div
                                    className='landingFont3'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        marginLeft: '25%',
                                        marginRight: '25%',
                                        textAlign: 'center'
                                    }}
                                >AVAP API Version Control is designed to create an API code repository and manage the different versions of your APIs across different deployment environments.
                                    It allows developers to maintain a detailed history of changes, manage updates efficiently, and handle multiple API versions simultaneously. With robust version control and continuous integration, AVAP ensures seamless transitions, backward compatibility, and consistent performance across all your API projects.
                                </div>

                            </div>
                        </div>

                        <div style={{
                            backgroundColor: '#ffffff',
                            height: '200px',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}>
                            {/*}
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>

            */}
                            <button
                                onClick={() => { history.push('/Register') }}
                                className='custom-button-emerald textBold'
                                style={{ marginTop: '20px' }}
                            >
                                Create my AVAP ID
                            </button>

                        </div>
                        <div style={{
                            backgroundColor: '#fafafa',
                            height: '920px',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}>
                            <div
                                className='landingFont1'
                            >
                                Top Benefits
                            </div>
                            <div
                                className='landingFont1'
                            >

                            </div>
                            <div
                                className='landingFont3'
                                style={{
                                    marginTop: '20px',
                                    width: '33%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            >
                                Here are the top three benefits of using AVAP API Version Control:
                            </div>


                            <div
                                id='bloque de columnas'
                                style={{
                                    width: '50%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    paddingTop: '80px',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={icn_1} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>Efficient Change Management</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>Its primary focus is on API creation, implementing fundamental processes like language commands and managing input and output data structures as native data structures.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={icn_2} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>Seamless Multi-Version Handling</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>Manage and maintain multiple API versions simultaneously, allowing developers to support legacy clients while continuously improving and updating newer versions without disruption.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={icn_3} style={{ width: '50%' }} width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold'>Robust Continuous Integration</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b'>Integrate version control with automated testing and deployment pipelines, ensuring that updates are thoroughly tested and smoothly integrated into the production environment, reducing the risk of errors and downtime.</div>
                                    </div>
                                </div>

                                {/*}
                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={lenguaje} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Lenguaje Alto Nivel</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Foco en las necesidades de creación de APIs, implementando procesos básicos como comandos del lenguaje y gestionando las estructuras de datos de entrada y salida como estructuras de datos nativas.</div>
                        </div>

                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={virtualidad} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Virtualidad</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>El atributo de virtualidad del lenguaje AVAP se da ya que sus definiciones dinámicas se alojan en la nube. Por lo que, para el desarrollo, el servidor de lenguaje únicamente lee los comandos, recupera estas definiciones y compone un Virtual Code que ejecuta la máquina virtual (parseador)</div>
                        </div>


                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={inteligencia} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Inteligencia</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Al es parte de nuestro ADN, integrándose en todas las funciones y capacidades de AVAP, desde la asistencia, desarrollo y hasta el enrutamiento de servidores.</div>
                        </div>
*/}



                            </div>


                        </div>
                        <div style={{ backgroundColor: 'white', height: '100%' }}>

                            <div style={{
                                backgroundColor: '#ffffff', height: '100%',
                                paddingTop: '80px',
                                paddingBottom: '80px',
                            }}>
                                <div className='landingFont0'
                                    style={{
                                        marginTop: '0px',

                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px',
                                        textAlign: 'center'
                                    }}
                                >Top AVAP Cloud <span className='textBold'>Benefits</span></div>
                                <div className='landingFont0'
                                    style={{
                                        marginTop: '0px',

                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px',
                                        textAlign: 'center'
                                    }}
                                ></div>

                                <div style={{ paddingTop: '40px', width: '100%', display: 'flex' }}>

                                    <div style={{ width: '50%' }}>
                                        <div className='landingFont3b' style={{ marginTop: '20px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Significant increase in team productivity.</div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Reduced need for technical specialization due to the language intelligence.</div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Increased productivity in functional areas.</div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Accelerated development speed.</div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Improvement in time to market, efficiency in development processes.</div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>ROI increase in product development.</div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Reduced maintenance costs, enabled by the language's virtual nature that facilitates optimization and API corrections within the same code.</div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '80%', marginRight: '10px', marginLeft: 'auto' }}><Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>Narrowing the knowledge gap between different areas, such as project development and clients.</div>

                                        {/*<div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Ensure security and control over data and API access management.</div>

    <div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Access detailed documentation and educational resources to facilitate integration and use of AVAP Framework.</div>*/}
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div style={{ marginTop: '0px', paddingLeft: '10px' }} className='landingFont2 textBold'>Virtual API execution process</div>
                                        <Image src={gafico_cn_i} style={{ width: '80%', marginLeft: '10px', marginTop: '20px', marginRight: 'auto' }} width="" />

                                    </div>

                                </div>

                            </div>


                        </div>




                        <div>
                            <FooterMenuAccess green={true} />
                        </div>

                    </div>
                </div>

            </BrowserView>





            <MobileView>
                <MobileNavbar />

                {/* <Disclaimer /> */}

                <div id='mobileVc' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='logos' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {/* <h1 className='text-center hero-title pb-5 text-center'>
                                    AVAP API Publisher
                                </h1> */}
                                {/* <p className='bodyText'>
                                    Here are the top three benefits of using AVAP API Publisher:
                                </p> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    AVAP API Version Control is designed to create an API code repository and manage the different versions
                                    of your APIs across different deployment environments.
                                    <br /><br />
                                    It allows developers to maintain a detailed history of changes, manage updates efficiently,
                                    and handle multiple API versions simultaneously. With robust version control and continuous integration,
                                    AVAP ensures seamless transitions, backward compatibility, and consistent performance across all your API projects.
                                </p>

                                {/* <button
                                    onClick={() => { history.push('/Register') }} className='greenbutton mt-5 d-table m-auto'>
                                    Create my AVAP ID
                                </button> */}
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="hero-title text-center pt-5">
                                        Top Benefits
                                    </h1>
                                    <p className='bodyText text-center textBold'>
                                        Here are the top three benefits of using AVAP API Publisher:
                                    </p>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image className='mt-5' src={icn_1} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>
                                        Efficient Change Management
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Tracking of every modification made to the APIs with a detailed history of changes,
                                        making it easy to roll back to previous versions or review past updates, ensuring smooth transitions between API versions.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={icn_2} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Seamless Multi-Version Handling
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Manage and maintain multiple API versions simultaneously,
                                        allowing developers to support legacy clients while continuously improving and updating newer versions without disruption.
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={icn_3} style={{ width: '50%' }} width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Robust Continuous Integration
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Integrate version control with automated testing and deployment pipelines,
                                        ensuring that updates are thoroughly tested and smoothly integrated into the production environment,
                                        reducing the risk of errors and downtime.
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </div>




    )
}

export default NewLandingVersionControl;