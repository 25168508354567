import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, Dropdown, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'
import Logo7 from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import favicon from '../../assets/img/Favicon_AVAP_Framework.png'

import icon1 from '../../assets/img/icons/ID_01.svg'
import icon2 from '../../assets/img/icons/ID_02.svg'
import icon3 from '../../assets/img/icons/ID_03.svg'
import icon4 from '../../assets/img/icons/ID_04.svg'

import iconId from '../../assets/img/logos/icono_ID.svg'

import logo_framework_titulo from '../../assets/img/AVAP_ID_horizontal.png'
import cabecera from '../../assets/img/foto-AVAP ID-2.png'
import cabeceraMobile from '../../assets/img/foto_AVA_ID_mobile.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';


import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import vineta_CN from '../../assets/img/vineta_avap.png'
import gafico_cn_i from '../../assets/img/grafico_avap_i.png'

import { BrowserView, MobileView } from 'react-device-detect';
import TopMenuBar from './TopMenuBar';


type DashProps = {
    sider: any
};



const NewLandingAVAPID = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // favicon
        return () => {
          document.head.removeChild(link);
        };
      }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <div>
            <BrowserView>
                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}></div>

                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}>

                <TopMenuBar/>



                    <div onClick={() => { cleanMenuAll() }}>

                        <div onClick={() => { cleanMenuAll() }} className="d-flex flex-column align-items-center">


                            <div className='d-flex flex-column  align-items-center'>

                                <Image src={cabecera} style={{ marginTop: '40px' }} width="" alt="Logo" />
                                <Image src={logo_framework_titulo} style={{
                                    marginTop: '80px',
                                    position: 'relative',
                                    width: '500px'
                                }} width="" alt="Logo" />
                                {/* 
                                <div
                                    className='landingFont0 txt-light'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px',
                                        fontFamily: 'Noto Sans", sans-serif'
                                    }}
                                >Your account for AVAP Framework  </div>


                                <div
                                    className='landingFont0 txt-light'
                                    style={{
                                        marginTop: '0px',
                                        position: 'relative',
                                        color: 'black',
                                        lineHeight: '50px',
                                        fontFamily: 'Noto Sans", sans-serif'
                                    }}
                                >
                                    Your AVAP ID will give you <span className=' textBold'>access to AVAP</span>
                                </div>
                                <div className='landingFont0 textBold' style={{ color: 'black', fontFamily: 'Noto Sans", sans-serif' }}>
                                    products and services.
                                </div>
                                 */}


                                <div
                                    className='txt-28'
                                    style={{
                                        marginTop: '80px',
                                        position: 'relative',
                                        color: 'black',
                                        marginLeft: '25%',
                                        marginRight: '25%',
                                        textAlign: 'center'
                                    }}
                                >
                                    Your AVAP ID is the key to unlocking the full potential of the AVAP Framework. With a single account, you can seamlessly access and manage all AVAP products and services.
                                    Whether you're developing APIs, managing cloud infrastructure, or collaborating with others, your AVAP ID ensures that all your tools are connected and ready to support your work.
                                </div>

                            </div>
                        </div>

                        <div style={{
                            backgroundColor: '#ffffff',
                            height: '200px',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}>
                            {/*}
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>

            */}
                            <button
                                onClick={() => { history.push('/Register') }}
                                className='greenbutton my-6'
                            >
                                Start with AVAP ID
                            </button>

                        </div>

                        <section className='w-100 bg-division mt-5'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center my-7 landingFont0 txt-light'>What is AVAP ID?</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center txt-28  mb-7'>
                                            Your AVAP ID is your personal account that grants access to the full suite of AVAP Framework products and services,
                                            such as AVAP Cloud, AVAP Interpreter & Language Server, AVAP Gateway Advanced, and AVAP Collaborative Network.
                                            It securely stores your contact information, sign-in credentials, payment methods, and security details.
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center my-7 landingFont0 txt-light'>When do I use my AVAP ID?</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center txt-28  mb-7'>
                                            You’ll use your AVAP ID whenever you set up a new device, make a purchase, or access any AVAP service.
                                            Signing in with the same AVAP ID ensures that all your AVAP products and services work together seamlessly,
                                            providing you with access to your content and projects across all your devices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='w-100'>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='text-center mt-7 mb-4 txt-60 txt-light'>Top <span className=' textBold'>Benefits</span></p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className='text-center txt-20'>Here are the top three benefits of your AVAP ID</p>
                                    </div>
                                </div>
                                <div className="row mt-6">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon1} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Single Access Point</p>
                                        <p className='txt-20'>
                                            Use your AVAP ID for seamless access to all AVAP Framework, ensuring an integrated and efficient experience across products and services.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon2} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Enhanced Security</p>
                                        <p className='txt-20'>
                                            Your AVAP ID is protected by advanced security features, keeping your personal data, projects, and payment information safe.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon3} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Cross-Platform Syncing</p>
                                        <p className='txt-20'>
                                            Keep your work, settings, and content synchronized across all devices and services connected to your AVAP account.
                                        </p>
                                    </div>
                                </div>
                                <div className="row mt-3 pb-7">
                                    <div className="col-md-3">
                                        <Image className='dataIcon' src={icon4} alt="icono" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className='txt-28 textBold mb-0'>Streamlined Management:</p>
                                        <p className='txt-20'>
                                        Manage all your AVAP products, purchases, and services from one central account, simplifying your user experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section id='lastSection' className='container'>
                            <div className="row">
                                <div className="col-md-12">
                                    <Image src={iconId} className='icon-65  d-block icon-fix' alt="Logo ID" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='txt-20 text-center'>
                                        With AVAP ID, you gain a secure, efficient, and unified way to
                                        <br /> manage your AVAP Framework products and services, making your
                                        <br /> API development journey faster and easier.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button onClick={() => { history.push('/Register') }}
                                        className='greenbutton d-block mb-fix-136'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>

                                </div>
                            </div>
                        </section>




                        <div>
                            <FooterMenuAccess green={true} />
                        </div>

                    </div>
                </div>

            </BrowserView>

            <MobileView>
            <MobileNavbar />



                {/* <Disclaimer /> */}

                <div id='mobileCloud' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabeceraMobile})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='logos' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    Your account for AVAP Framework. Your AVAP ID will give you
                                    <span className='textBold'>
                                        &nbsp;access to AVAP products and services.
                                    </span>
                                </h1>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    Your AVAP ID is the key to unlocking the full potential of the AVAP Framework.
                                    With a single account, you can seamlessly access and manage all AVAP products and services. Whether you're developing APIs,
                                    managing cloud infrastructure, or collaborating with others, your AVAP ID ensures that all your tools are connected and ready to support your work.

                                </p>
                                {/*}  <button onClick={() => { history.push('/Register') }} className='bluebutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>

                    <section className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-6'>What is AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        Your AVAP ID is your personal account that grants access to the full suite of AVAP Framework products and services, such as AVAP Cloud, AVAP Interpreter & Language Server, AVAP Gateway Advanced, and AVAP Collaborative Network.
                                        It securely stores your contact information, sign-in credentials, payment methods, and security details.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-4'>When do I use my AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        You’ll use your AVAP ID whenever you set up a new device, make a purchase, or access any AVAP service.
                                        Signing in with the same AVAP ID ensures that all your AVAP products and services work together seamlessly,
                                        providing you with access to your content and projects across all your devices.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='w-100'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center mt-7 mb-4 txt-60 txt-light'>Top <span className=' textBold'>Benefits</span></p>
                                </div>
                                <div className="col-md-12">
                                    <p className='text-center txt-20 text-center'>Here are the top three benefits of your AVAP ID</p>
                                </div>
                            </div>
                            <div className="row mt-6">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon1} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Single Access Point</p>
                                    <p className='txt-20 text-center'>
                                    Use your AVAP ID for seamless access to all AVAP Framework, ensuring an integrated and efficient experience across products and services.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon2} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Enhanced Security</p>
                                    <p className='txt-20 text-center'>
                                    Your AVAP ID is protected by advanced security features, keeping your personal data, projects, and payment information safe.
                                        </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon3} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Cross-Platform Syncing</p>
                                    <p className='txt-20 text-center'>
                                    Keep your work, settings, and content synchronized across all devices and services connected to your AVAP account.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3 pb-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={icon4} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Streamlined Management:</p>
                                    <p className='txt-20 text-center'>
                                        Manage all your AVAP products, purchases, and services from one central account, simplifying your user experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id='lastSection' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={iconId} className='icon-65  d-block icon-fix' alt="Logo ID" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='txt-20 text-center mb-7'>
                                    With AVAP ID, you gain a secure, efficient, and unified way to
                                    <br /> manage your AVAP Framework products and services, making your
                                    <br /> API development journey faster and easier.
                                </p>
                            </div>
                        </div>

                        {/* <div className="row">
                                <div className="col-md-12">
                                    <button onClick={() => { history.push('/Register') }}
                                        className='greenbutton d-block mb-fix-136'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>

                                </div>
                            </div> */}
                    </section>




                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </div>
    )
}

export default NewLandingAVAPID;