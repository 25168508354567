//libraries
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, Dropdown, NavDropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'

//components
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';

//assets
import { faStar, } from '@fortawesome/free-solid-svg-icons';
import logo_framework from '../../assets/img/avap_logo.png'
import logoMarketplace from '../../assets/img/AVAP_Marketplace.png'
import favicon from '../../assets/img/Favicon_AVAP_Framework.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'

import icon_dev from '../../assets/img/logos/icono_DSs.svg'
import icon_api from '../../assets/img/logos/icon_APIControVersion.svg'
import icon_publisher from '../../assets/img/logos/icono_publisher.svg'
import icon_linter from '../../assets/img/logos/icon_avap.svg'

import icon_ln from '../../assets/img/ui_icons/icono_Socialmedia_In@2x.png'
import icon_x from '../../assets/img/ui_icons/icono_Socialmedia_X@2x.png'



import { BrowserView, MobileView } from 'react-device-detect';
import TopMenuBar from './TopMenuBar';


type DashProps = {
    sider: any
};



const MarketplaceStoreCompany = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }

    useEffect(() => {
        // favicon
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);

        // favicon
        return () => {
            document.head.removeChild(link);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <div>
            <BrowserView>
            <TopMenuBar/>
                <div id='marketplaceStoreDetail' className="w-100 pt-50">
                    <div id="topBar">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="linksWrapper">
                                        <a href="http://">
                                            <a href="/marketplaceStore" className=''>Home</a>
                                        </a>
                                        <span>|</span>
                                        <a href="/marketplaceStore/company" className='c-white'>Company</a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div id='topInfo' className='w-100 bg-division'>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="">
                                        <Image src={logoMarketplace} className='logoCompany' />

                                        <p className="desc txt-20 c-black">
                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                            <br />
                                            <a href="">Read more</a>
                                            <br />

                                            <a href="" className='mt-5 d-block'>http://www.avapframework.com</a>
                                        </p>

                                        <div className="socialLinks">
                                            <a href="">
                                                <Image src={icon_ln} className='logoCompany' />
                                            </a>
                                            <a href="">
                                                <Image src={icon_x} className='logoCompany' />
                                            </a>
                                        </div>

                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <Container id='marketplaceStore'>
                        <Row id="apiProducts">
                            <Col lg="12" className='sectionTitle'>
                                <p>API Products</p>
                            </Col>

                            <Row>
                                <Col md="3">
                                    <a href="/marketplaceStore/devExtension" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_dev} />
                                        </div>
                                        <div className='title'>AVAP Dev Extension</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href=""></a>
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_api} />
                                        </div>
                                        <div className='title'>AVAP API Version Control</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_publisher} />
                                        </div>
                                        <div className='title'>AVAP API Publisher</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_linter} />
                                        </div>
                                        <div className='title'>AVAP Linter & Completion</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                            </Row>
                            <Row className='mt-5'>
                                <Col md="3">
                                    <a href="/marketplaceStore/devExtension" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_dev} />
                                        </div>
                                        <div className='title'>AVAP Dev Extension</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href=""></a>
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_api} />
                                        </div>
                                        <div className='title'>AVAP API Version Control</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_publisher} />
                                        </div>
                                        <div className='title'>AVAP API Publisher</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_linter} />
                                        </div>
                                        <div className='title'>AVAP Linter & Completion</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                            </Row>
                            <Row className='mt-5 mb-7'>
                                <Col md="3">
                                    <a href="/marketplaceStore/devExtension" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_dev} />
                                        </div>
                                        <div className='title'>AVAP Dev Extension</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href=""></a>
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_api} />
                                        </div>
                                        <div className='title'>AVAP API Version Control</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_publisher} />
                                        </div>
                                        <div className='title'>AVAP API Publisher</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                                <Col md="3">
                                    <a href="" className="storeCard">
                                        <div className='icon'>
                                            <Image src={icon_linter} />
                                        </div>
                                        <div className='title'>AVAP Linter & Completion</div>
                                        <div className='infoDw'>
                                            <div className="dec">
                                                Lorem impsum
                                            </div>
                                            <div className="downloads">
                                                23m
                                            </div>
                                        </div>
                                        <div className="info">
                                            <a href="http://">http://www.web.com</a>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            </p>
                                        </div>
                                        <div className="bottom">
                                            <div className="stars">
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                                <FontAwesomeIcon icon={faStar} className="c-brick" size="1x" />
                                            </div>
                                            <div className="type">
                                                Free
                                            </div>
                                        </div>

                                    </a>
                                </Col>
                            </Row>





                        </Row>
                    </Container>


                </div>
                <FooterMenuAccess green={true} />

            </BrowserView>


        </div>
    )
}

export default MarketplaceStoreCompany;