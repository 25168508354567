import React, { FunctionComponent, useEffect, useState } from 'react';
import { Navbar, Container, Nav, Image, Dropdown, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'


import ai_1 from '../../assets/img/ai_1.png'
import ai_2 from '../../assets/img/ai_2.png'
import ai_3 from '../../assets/img/ai_3.png'
import ai_4 from '../../assets/img/ai_4.png'
import ai_5 from '../../assets/img/ai_5.png'
import ai_6 from '../../assets/img/ai_6.png'

import vineta_CN from '../../assets/img/vineta_AI.png'
import gafico_cn_i from '../../assets/img/grafico_AI_i.png'
import gafico_cn_mobile from '../../assets/img/graph_ai_mobile.svg'


import logo_framework_titulo from '../../assets/img/logos/AVAP_AI.svg'
import cabecera from '../../assets/img/foto_AI.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import cabeceraMobile from '../../assets/img/foto_AI_movil.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';


import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';
import TopMenuBar from './TopMenuBar';

type DashProps = {
    sider: any
};



const NewLandingAI = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };



    return (
        <>


            <BrowserView>

                <div className='d-flex flex-column' style={{ backgroundColor: '#ffffff' }}>

                <TopMenuBar/>



                    <div onClick={() => { cleanMenuAll() }}>

                        <div onClick={() => { cleanMenuAll() }} className="d-flex flex-column align-items-center">


                            <div style={{ paddingBottom: '80px' }} className='d-flex flex-column  min-vh-100 align-items-center'>

                                <Image src={cabecera} style={{ marginTop: '40px' }} width="" alt="Logo" />
                                <Image src={logo_framework_titulo} style={{
                                    marginTop: '80px',
                                    position: 'relative',
                                    width: '500px'
                                }} width="" alt="Logo" />

                                <div
                                    className='txt-60 txt-light mt-7'

                                >AVAP AI aka Brunix, the artificial</div>


                                <div
                                    className='txt-60 txt-light lnh-40'
                                >intelligence where <span className='txt-bold'>you are the copilot</span></div>


                                <div
                                    className='txt-28 text-center mt-7'

                                >Artificial intelligence integrated into the core of all our AVAP products <br />
                                    transcends mere Copilot functionality; it maximizes efficiency and <br />
                                    enhances productivity in programming and API development.</div>

                            </div>
                        </div>

                        <div style={{
                            backgroundColor: '#fafafa',
                            height: '100%',
                            textAlign: 'center',
                            paddingTop: '30px'
                        }}>
                            {/*}
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>

            
                <button 
                onClick={()=>{history.push('/Register')}}
                    className='custom-button-emerald textBold'
                    style={{ marginTop:'20px' }}
                >
                    Create my AVAP ID
                </button>
*/}
                        </div>
                        <div className='bg-division' style={{

                            height: '100%',
                            textAlign: 'center',
                            paddingTop: '0px',
                            paddingBottom: '80px'
                        }}>

                            {/*}
                <div 
                    className='landingFont1'
                >
                    The total API solution
                </div>
                <div 
                    className='landingFont1'
                >
                   
                </div>
                <div 
                    className='landingFont3'
                    style={{ 
                        marginTop:'20px',
                        width:'33%', 
                        marginLeft:'auto', 
                        marginRight:'auto' 
                    }}
                >
                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.
                </div>
*/}

                            <div
                                id='bloque de columnas'
                                style={{
                                    width: '50%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    paddingTop: '80px',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={ai_1} className='icon-100' width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='txt-28 txt-bold'>AI at the core</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='txt-20'>AI is ingrained in our DNA, seamlessly integrating into all functions and capabilities. Within the AVAP code are commands empowering Al to execute proposed solutions via natural language directives.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={ai_2} className='icon-100' width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='txt-28 txt-bold'>AI throughout the process</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='txt-20'>BRUNIX plays a crucial role in server assistance, development, and routing. It analyzes data collected from calls and uses artificial intelligence to make routing decisions.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={ai_3} className='icon-100' width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='txt-28 txt-bold'>Real guidance</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='txt-20'>
                                        Artificial intelligence generates AVAP code proposals or Low Code 
                                         in response to natural language, leveraging its comprehensive understanding of the language.
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={ai_4} className='icon-100' width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='txt-28 txt-bold'>Automatic Optimization</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='txt-20'>AVAP AI aka BRUNIX, analyzes deployments and proposes modifications based on operational data.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={ai_5} className='icon-100' width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='txt-28 txt-bold'>Copilot BRUNIX</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='txt-20'>Artificial intelligence continuously analyzes code, anticipating and suggesting solutions proactively without explicit requests.</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', paddingTop: '50px' }}>
                                    <div style={{ width: '20%' }}>
                                        <Image src={ai_6} className='icon-100' width="" />
                                    </div>
                                    <div style={{ width: '80%' }}>
                                        <div style={{ marginTop: '0px', textAlign: 'start' }} className='txt-28 txt-bold'>Automatic code debugging</div>
                                        <div style={{ marginTop: '20px', textAlign: 'start' }} className='txt-20'>Analyzes, identifies, and proposes improvements to code after it has been programmed.</div>
                                    </div>
                                </div>

                                {/*}
                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={lenguaje} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Lenguaje Alto Nivel</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Foco en las necesidades de creación de APIs, implementando procesos básicos como comandos del lenguaje y gestionando las estructuras de datos de entrada y salida como estructuras de datos nativas.</div>
                        </div>

                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={virtualidad} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Virtualidad</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>El atributo de virtualidad del lenguaje AVAP se da ya que sus definiciones dinámicas se alojan en la nube. Por lo que, para el desarrollo, el servidor de lenguaje únicamente lee los comandos, recupera estas definiciones y compone un Virtual Code que ejecuta la máquina virtual (parseador)</div>
                        </div>


                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={inteligencia} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Inteligencia</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Al es parte de nuestro ADN, integrándose en todas las funciones y capacidades de AVAP, desde la asistencia, desarrollo y hasta el enrutamiento de servidores.</div>
                        </div>
*/}



                            </div>


                        </div>



                        <div style={{
                            backgroundColor: '#ffffff', height: '100%',
                            paddingTop: '80px',
                            paddingBottom: '80px',
                            textAlign: 'start'
                        }}>
                            <div className='txt-60 text-center txt-light lnh-70'
                            >Top AVAP AI aka <br /> Brunix <span className='txt-bold'>Benefits</span></div>
                            <div className='landingFont0'
                                style={{
                                    marginTop: '0px',

                                    position: 'relative',
                                    color: 'black',
                                    lineHeight: '50px',
                                    textAlign: 'center'
                                }}
                            ></div>

                            <div className='container-fluid'>
                                <div className="row mt-7">
                                    <div className='col-md-6'>
                                        <div className='landingFont3b' style={{ marginTop: '20px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>


                                            <p className="txt-20">
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Al is seamlessly integrated into the product's DNA, enhancing operational efficiency, anticipating needs and issues,
                                                and delivering rapid responses.
                                            </p>
                                        </div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>
                                            <p className='txt-20'>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Artificial intelligence optimizes the internal functioning of products,
                                                adapting to current requirements and conditions in real time with intelligent decision-making.
                                            </p>
                                        </div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>
                                            <p className="txt-20">
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Enhances operation and optimization, boosting efficiency in the development and management of APIs.
                                            </p>
                                        </div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>
                                            <p className='txt-20'>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Reduces the learning curve, enabling developers to quickly start creating APIs.
                                            </p>
                                        </div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>
                                            <p className='txt-20'>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Boosts productivity by automating tasks and optimizing processes.
                                            </p>
                                        </div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>
                                            <p className="txt-20">
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Continuous improvement of functionalities and development performance.
                                            </p>
                                        </div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>
                                            <p className="txt-20">
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Adaptability due to its ability to dynamically adjust to changes.
                                            </p>
                                        </div>

                                        <div className='landingFont3b' style={{ marginTop: '10px', width: '60%', marginRight: '10px', marginLeft: 'auto' }}>
                                            <p className='txt-20'>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Reduces operational costs by automating the development and maintenance processes of APIs.
                                            </p>
                                        </div>

                                        {/*<div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Ensure security and control over data and API access management.</div>

    <div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Access detailed documentation and educational resources to facilitate integration and use of AVAP Framework.</div>*/}
                                    </div>
                                    <div className='col-md-6'>
                                        <div style={{ marginTop: '0px', paddingLeft: '10px' }} className='landingFont2 textBold c-black'>AI Integration</div>
                                        <Image src={gafico_cn_i} style={{ width: '80%', marginLeft: '10px', marginTop: '20px', marginRight: 'auto' }} width="" />
                                        <div style={{ marginTop: '0px', paddingLeft: '10px' }} className='txt-20'>
                                            *Integrated full functional capabilities in <br />commercial releases by 2024.
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>




                        <div>
                            <FooterMenuAccess green={true} />
                        </div>

                    </div>
                </div>

            </BrowserView>



            <MobileView>

                <MobileNavbar />

                {/* <Disclaimer /> */}

                <div id='mobileAi' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabeceraMobile})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='logos' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    AVAP AI aka Brunix, the artificial intelligence where
                                    <span className='txt-bold'>
                                        &nbsp;you are the copilot
                                    </span>
                                </h1>
                            </div>
                        </div>
                        <div className="row mt-46">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    Artificial intelligence integrated into the core of all our
                                    AVAP products transcends mere Copilot functionality; it maximizes
                                    efficiency and enhances productivity in programming and API development.
                                </p>
                                {/*}  <button onClick={() => { history.push('/Register') }} className='bluebutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>

                    {/* <section className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-6'>What is AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        Your AVAP ID is your personal account that grants access to the full suite of AVAP Framework products and services, such as AVAP Cloud, AVAP Interpreter & Language Server, AVAP Gateway Advanced, and AVAP Collaborative Network.
                                        It securely stores your contact information, sign-in credentials, payment methods, and security details.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-4'>When do I use my AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        You’ll use your AVAP ID whenever you set up a new device, make a purchase, or access any AVAP service.
                                        Signing in with the same AVAP ID ensures that all your AVAP products and services work together seamlessly,
                                        providing you with access to your content and projects across all your devices.</p>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className='w-100 bg-division'>
                        <div className="container">
                            {/* <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center mt-7 mb-4 txt-60 txt-light'>Top <span className=' textBold'>Benefits</span></p>
                                </div>
                                <div className="col-md-12">
                                    <p className='text-center txt-20 text-center'>Here are the top three benefits of your AVAP ID</p>
                                </div>
                            </div> */}
                            <div className="row mt-6">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_1} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>AI at the core</p>
                                    <p className='txt-20 text-center'>
                                        AI is ingrained in our DNA, seamlessly integrating into all functions and capabilities.
                                        Within the AVAP code are commands empowering AI to execute proposed solutions via natural language directives.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_2} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>AI throughout the process</p>
                                    <p className='txt-20 text-center'>
                                        BRUNIX plays a crucial role in server assistance, development, and routing.
                                        It analyzes data collected from calls and uses artificial intelligence to make routing decisions.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_3} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Real guidance</p>
                                    <p className='txt-20 text-center'>
                                        Artificial intelligence generates AVAP code proposals or Low Code implementations
                                        in response to natural language, leveraging its comprehensive understanding of the language.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_4} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Automatic Optimization</p>
                                    <p className='txt-20 text-center'>
                                        AVAP AI aka BRUNIX, analyzes deployments and proposes modifications based on operational data.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_5} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Copilot BRUNIX</p>
                                    <p className='txt-20 text-center'>
                                        Artificial intelligence continuously analyzes code,
                                        anticipating and suggesting solutions proactively without explicit requests.
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-3 pb-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_6} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Automatic code debugging</p>
                                    <p className='txt-20 text-center'>
                                        Analyzes, identifies, and proposes improvements to code after it has been programmed.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section id='lastSection' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={iconId} className='icon-65  d-block icon-fix' alt="Logo ID" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='txt-20 text-center mb-7'>
                                    With AVAP ID, you gain a secure, efficient, and unified way to
                                    <br /> manage your AVAP Framework products and services, making your
                                    <br /> API development journey faster and easier.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                                <div className="col-md-12">
                                    <button onClick={() => { history.push('/Register') }}
                                        className='greenbutton d-block mb-fix-136'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>

                                </div>
                            </div>
                    </section> */}

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP AI aka Brunix Benefits
                                </h1>
                            </div>
                            {/* <div className="col-md-12">
                                <p className='title'>Virtual API execution process</p>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    AI is seamlessly integrated into the product’s DNA, enhancing operational efficiency,
                                    anticipating needs and issues, and delivering rapid responses.


                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Artificial intelligence optimizes the internal functioning of products,
                                    adapting to current requirements and conditions in real time with intelligent decision-making.


                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Enhances operation and optimization, boosting efficiency in the development and management of APIs.

                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Increased productivity.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>

                                    Reduces the learning curve, enabling developers to quickly start creating APIs.


                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Boosts productivity by automating tasks and optimizing processes.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Continuous improvement of functionalities and development performance.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>

                                    Adaptability due to its ability to dynamically adjust to changes.

                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Reduces operational costs by automating the development and maintenance processes of APIs.
                                </div>

                            </div>

                            <div className="col-md-6">
                                <p className='txt-20 txt-bold my-5 text-center'>AI Integration</p>
                                <Image src={gafico_cn_mobile} style={{ width: '100%', padding: "10px" }} width="" />
                            </div>
                        </div>
                    </section>




                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>

        </>

    )
}

export default NewLandingAI;